<template>
  <div>
    <div class="messages">
      <c-box style="position: relative" bg="vc-white.20">
        <c-flex
          class="header"
          align="['start','start', 'space-between']"
          :flexDirection="['column', 'column', 'row']"
          justify="space-between"
          mb="2"
        >
          <c-flex
            pt="10px"
            justify="['space-between','start', 'space-between']"
            align="center"
          >
            <div class="messaging_head">
              <h1>Messaging</h1>

              <div class="msg_icons">
                <c-menu>
                  <c-menu-button variant="link" width="15px">
                    <i class="fas fa-plus-square"></i>
                  </c-menu-button>
                  <c-menu-list>
                    <c-menu-item @click="openNewChat"> New Chat</c-menu-item>
                    <c-menu-item @click="openNewChannel"
                      ># New Channel</c-menu-item
                    >
                  </c-menu-list>
                </c-menu>
              </div>
            </div>
          </c-flex>
          <c-flex align="center" justify="['start','end', 'space-between']">
            <search @hide-sidebar="handleHideSidebar" />

            <HeaderOptions />
          </c-flex>
        </c-flex>
      </c-box>

      <div class="messages__container">
        <div class="messages__sidebar">
          <!-- <div class="search_input">
          <c-input-group>
            <c-input-left-element>
              <i class="fas fa-search"></i>
            </c-input-left-element>
            <c-input
              placeholder="Search people here"
              borderWidth="0"
              borderRadius="20"
              size="lg"
              variant="filled"
              type="text"
            />
          </c-input-group>
        </div> -->

          <div class="messages__sidebar__start">
            <div>
              <div class="msgTitle">
                <span>Direct Messages </span>
                <span @click="clearAllMessageNotification"
                  >Mark all as read</span
                >
              </div>
              <div class="channelList">
                <UsersMessageList />
              </div>
              <br />
              <div class="msgBtn" @click="openNewChat">
                <span class="msgAdd">
                  <i class="fas fa-plus"></i>
                </span>

                <p>Start Conversation</p>
              </div>
            </div>

            <br />
            <div class="msgTitle">
              <span
                >Channels
                <c-badge mx="2" variant-color="red">{{
                  channelsTotal
                }}</c-badge>
              </span>
            </div>

            <div class="channelList">
              <JoinedChannelsList @getTotal="setTotal" />
            </div>

            <div class="msgBtn" @click="showChannelList = true">
              <span class="msgAdd">
                <i class="fas fa-plus"></i>
              </span>

              <p>Join Channel</p>
            </div>
          </div>
        </div>

        <div class="messages__content">
          <router-view></router-view>
        </div>
      </div>
    </div>

    <ChannelLists @close="showChannelList = false" v-if="showChannelList" />

    <UsersYouCanMessage @close="showUsersList = false" v-if="showUsersList" />
  </div>
</template>

<script>
import store from '@/store';
import { mapActions } from 'vuex';
import ChannelLists from './components/ChannelLists.vue';
import UsersYouCanMessage from './components/UsersYouCanMessage.vue';
import UsersMessageList from './components/UsersMessageList.vue';
import JoinedChannelsList from './components/JoinedChannelsList.vue';

import HeaderOptions from '@/views/App/components/HeaderOptions.vue';
import Search from '@/views/App/Explore/Search.vue';

import { updateUser } from '@/services/user.js';

export default {
  components: {
    ChannelLists,
    UsersYouCanMessage,
    JoinedChannelsList,
    UsersMessageList,
    HeaderOptions,
    Search,
  },
  data() {
    return {
      channelsTotal: 0,
      showChannelList: false,
      showUsersList: false,
    };
  },
  mounted() {
    updateUser({
      id: store.state.auth.user.id,
      set: { OnlineStatus: 'true' },
    }).then((response) => {
      console.log(response);
    });
  },
  methods: {
    ...mapActions({
      clearAllMessageNotification: 'messaging/clearAllMessageNotification',
    }),
    // async refreshTk() {
    //   const payload = {
    //     refresh_token: store.state.auth.token.refreshToken,
    //   };

    //   const response = await refreshToken(payload);
    //   console.log(response);
    //   console.log('we got it here', response.data);
    //   await onLogin(this.$apollo.provider.defaultClient, response.data);
    // },
    setTotal(total) {
      this.channelsTotal = total;
    },
    openNewChannel() {
      this.$router.push({ name: 'CreateNewChannel' });
    },
    openNewChat() {
      this.showUsersList = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.messages {
  height: 100vh;
  background-color: #fafbfd;

  &__container {
    display: flex;
  }
  &__sidebar {
    width: 300px;
    margin: 20px 20px 0px 0px;
    height: 95vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .search_input {
      svg {
        color: var(--color-grey);
      }
    }

    &__start {
      .channelList {
        max-height: 400px;
        overflow-y: scroll;
      }

      .msgTitle {
        /* Rectangle 88 */
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgba(66, 103, 178, 0.14);
        border-radius: 3px;

        span {
          padding: 10px 10px;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: var(--color-grey);
          cursor: pointer;
        }
      }

      .msgBtn {
        display: flex;
        align-items: center;
        margin-top: 15px;

        .msgAdd {
          /* Rectangle 89 */
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(147, 169, 214, 0.2);
          border-radius: 5px;
          margin-right: 10px;

          svg {
            color: #6b6b6b;
            font-size: 20px;
          }
        }

        p {
          color: #6b6b6b;
          font-size: 15px;
          cursor: pointer;
        }
      }
    }
  }

  &__content {
    background: rgba(222, 232, 253, 0.43);
    width: 60%;
    padding: 20px 0px 30px 0px;
  }
}

.messaging_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;

  h1 {
    font-weight: 700;
    font-size: 20px;
    color: #1a202c;
  }

  .msg_icons {
    display: flex;
    align-items: center;

    svg {
      color: var(--color-secondary);
      margin-left: 10px;
      font-size: 20px;
    }
  }
}
</style>
